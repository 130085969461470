<template>
  <div class="container" @click="sellerSelect = false">
    <div style="display: flex; justify-content: space-between; align-items: end; margin-bottom: 30px;">
      <BaseHeader
        title="Histórico de mensagens"
        :navigation="navigation"
      ></BaseHeader>
      <BaseButton style="display: flex; align-items: center; width: 250px; gap: 10px;" variant="primary" @click="confirmGWhats" v-if="!loading">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" y1="15" x2="12" y2="3"></line></svg>
        <span style="color: inherit;">Instalar GWhats</span>
      </BaseButton>
    </div>
    

    <div class="container">
      <div v-if="!$store.getters.recursos.nhistory" class="__blocked">
        <svg fill="#4ea934" height="150px" width="150px" version="1.1" viewBox="0 0 330 330" ><g stroke-width="0"></g><g stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="XMLID_509_"> <path id="XMLID_510_" d="M65,330h200c8.284,0,15-6.716,15-15V145c0-8.284-6.716-15-15-15h-15V85c0-46.869-38.131-85-85-85 S80,38.131,80,85v45H65c-8.284,0-15,6.716-15,15v170C50,323.284,56.716,330,65,330z M180,234.986V255c0,8.284-6.716,15-15,15 s-15-6.716-15-15v-20.014c-6.068-4.565-10-11.824-10-19.986c0-13.785,11.215-25,25-25s25,11.215,25,25 C190,223.162,186.068,230.421,180,234.986z M110,85c0-30.327,24.673-55,55-55s55,24.673,55,55v45H110V85z"></path> </g> </g></svg>
        <span style="color: inherit; " class="title">Acesso bloqueado</span>
        <span style="font-size: 14px; color: inherit; opacity: 0.7;">Esse recurso ainda não está incluso em seu plano,<br> fale com nosso time comercial para mais informações.</span>
        <button @click="upgradePlan()">Fazer upgrade</button>
      </div>


      <div v-if="!disappear" @mouseenter.once="$store.getters.recursos.nhistory ? '' : disappear = true">
        <div class="d-flex justify-content-center" v-if="loading">
          <b-spinner label="Loading..."></b-spinner>
        </div>
  
        <div class="box-zap" v-if="!loading">
          <div class="log-header user-header" @click.stop="sellerSelect = !sellerSelect">
            <img v-if="vendedor.photoLead" :src="vendedor.photoLead" alt="">
            <div v-else class="initial small all-white">{{getMetaInitial(vendedor)}}</div> 
            <div class="user-info">
              <span class="name">{{ vendedor.first_name }} {{ vendedor.last_name }}</span>
              <span class="email">{{ vendedor.email }}</span>
            </div>
            <div :style="sellerSelect ? 'transform: rotate(180deg);' : ''" class="select"/>
            <Transition name="AnimationRight" v-show="sellerSelect">
              <div @click.stop="" class="seller-select" v-if="sellerSelect">
                <div class="lead-search2">
                  <img
                    src="@/assets/img/icons/search.svg"
                    class="Pointer searchIcon"
                  />
                  <input v-focus type="text" v-model="sellerSearch" @input="sellerSearch ? debounceVendedor(sellerSearch) : vendedores = []" />
                </div>
                <div v-if="vendedores.length">
                  <div
                    style="padding: 5px 10px !important"
                    v-for="(item, index) in vendedores"
                    :key="index"
                    class="sub-card vendedores"
                    :style="item.id == vendedor.id ? 'cursor: default; background-color: #f1f1f1 !important;' : ''"
                    @click.stop="item.id == vendedor.id ? '' : newLeads(item)"
                  >
                    <div style="display: flex; align-items: center">
                      <div style="margin-right: 10px">
                        <div v-if="item.photoLead">
                          <img
                            style="margin: 0 !important"
                            class="seller-photo"
                            :src="
                              item.photoLead
                                .meta_value
                            "
                            alt=""
                          />
                        </div>
                        <div
                          v-else
                          class="initial small"
                          style="margin: 0 !important"
                        >
                          {{ getMetaInitial(item) }}
                        </div>
                      </div>
                      <span class="sellers-name">
                        <label
                          :style="item.id == vendedor.id ? 'cursor: default;' : 'cursor: pointer;'"
                          style="margin: 0 !important"
                          for=""
                          >{{ item.first_name ? item.first_name : "" }}
                          {{ item.last_name ? item.last_name : "" }}</label
                        >
                        {{ item.email }}
                      </span>
                    </div>
                  </div>
                  <div v-if="!stopVendedor && !sellerSearch" class="observer" v-observe-visibility="debounceVendedor"/>
                </div>
                <span v-else>Nenhum vendedor encontrado</span>
              </div>
            </Transition>
          </div>
          <div class="log-header lead-header">
            <div v-if="selectedLead && selectedLead.id" class="lead-exist">
              <img v-if="selectedLead.photoLead" :src="selectedLead.photoLead" alt="" @click="openLead(selectedLead.id)">
              <div v-else class="initial small all-white" @click="openLead(selectedLead.id)">{{returnInitial(selectedLead)}}</div> 
              <div class="user-info" @click="openLead(selectedLead.id)">
                <span class="name">{{ selectedLead.name }}</span>
                <span class="email">{{ selectedLead.phone }}</span>
              </div>
            </div>
          </div>
          <div class="body-left">
            <div class="lead-search">
              <img src="@/assets/img/icons/search.svg"/>
              <input type="text" placeholder="Pesquise pelo lead" v-model="leadSearch" @input="clean = true, debounceLeads()">
            </div>
            <div v-if="leads.length">
              <div v-for="(lead, index) in leads" :key="index" class="contact" :class="selectedLead && selectedLead.id && lead.id == selectedLead.id ? 'selected-contact' :''" @click="selectedLead = lead">
                <img v-if="lead.photoLead" :src="lead.photoLead" alt="Foto do lead">
                <div v-else class="initial">{{returnInitial(lead)}}</div>
                <div>
                  <span class="lead-name">{{ lead.name ? lead.name : 'Lead sem nome' }}</span>
                  <span class="lead-phone">{{ lead.phone ? lead.phone : 'Lead sem telefone' }}</span>
                </div>
              </div>
              <div class="observer" v-if="!stopLeads && !leadSearch" v-observe-visibility="clean = false, debounceLeads()"></div>
            </div>
          </div>
          <WhatsAppMessages ref="WhatsAppMessages" style="width: 100%" :lead="selectedLead" :seller="vendedor" />
        </div>
        <div v-else>
          <b-row class="justify-content-center style">
            <p class="nao-encontrado">Nenhuma mensagem foi encontrada</p>
          </b-row>
        </div>

      </div>


    </div>

    
  </div>
</template>

<script>
import BaseView from "@/template/BaseView.vue";
import _ from "lodash";

import LeadMessagesService from "@/services/resources/LeadMessageService";
const LogMessages = LeadMessagesService.build();
import SellerListService from "@/services/resources/SellerListService";
import Vue from "vue";
const serviceSeller = SellerListService.build();

import WhatsAppMessages from "@/components/Apps/LogMessages/WhatsAppMessages.vue";

export default {
  components: {
    BaseView,
    WhatsAppMessages,
  },

  data() {
    return {
      // Vendedores
      vendedores: [],
      vendedor: this.$store.getters.user.user,
      sellerSearch: '',
      stopVendedor: false,
      pageVendedor: 1,
      sellerSelect: false,
      clean: false,
      // Leads
      leadsPage: 1,
      leadSearch: '',
      leads: [],
      selectedLead: [],
      stopLeads: false,
      // Configs
      dynamicRoute: Cookies.get("tenantSubdomain"),
      navigation: [
        { link: "Estratégico", to: "/dynamicRoute/estrategico" },
        { link: "Histórico de Mensagens", to: this.$route.fullPath },
      ],
      level: this.$store.getters.user.user.level,
      loading: false,
      client: {
        width: 0,
      },
      disappear: false
    };
  },

  computed: {
    isMobile() {
      return this.client.width <= 768;
    },
  },
  methods: {
    openLead(id) {
      const url = `${process.env.BASE_URL}${this.$store.getters.user.user.tenant_subdomain}/lead/${id}`;
      window.open(url, "_blank");
    },
    upgradePlan() {
        $crisp.push(["set", "session:segments", [["financeiro"]]]);
        $crisp.push(["do", "chat:open"]);
        $crisp.push([
            "do",
            "message:send",
            ["text", `Quero fazer um upgrade no meu plano e ter acesso ao CRM.`],
        ]);
    },
    confirmGWhats() {
      Vue.swal({
        title: "G Whats",
        text: `Você deseja realizar o download do aplicativo do G Whats em seu dispositivo?`,
        // type: "danger",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        customClass: "sweet-container",
        confirmButtonClass: "button button-black mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          var url =
            "https://gdigital.sfo2.cdn.digitaloceanspaces.com/whats2/gwhats.exe";
          if (navigator.platform.indexOf("Mac") !== -1) {
            url =
              "https://gdigital.sfo2.cdn.digitaloceanspaces.com/whats2/gwhats.zip";
          }
          // Verificar se o sistema operacional é Linux
          else if (navigator.platform.indexOf("Linux") !== -1) {
            url =
              "https://gdigital.sfo2.cdn.digitaloceanspaces.com/whats2/gwhats.deb";
          }
          window.open(url, "_blank");
        }
      });
    },
    debounceVendedor: _.debounce(function (query) {
      if (query && query.length) {
        this.pageVendedor = 1;
        this.stopVendedor = false;
        this.getVendedor();
      } else {
        this.getVendedor();
      }
    }, 500),
    getVendedor() {
      if (this.stopVendedor) {
        return;
      }
      if (this.sellerSearch) {
        var data = {
          page: `1&order_by=email&order=ASC&search=${this.sellerSearch}`,
        };
      } else {
        var data = {
          page: `${this.pageVendedor}&order_by=email&order=ASC&per_page=8`,
        };
      }

      serviceSeller
        .search(data)
        .then((resp) => {
          if (resp.data.length < resp.per_page && !this.sellerSearch) {
            this.stopVendedor = true;
          }
          if (this.sellerSearch) {
            this.vendedores = resp.data;
          } else {
            this.pageVendedor++
            this.vendedores = this.vendedores.concat(resp.data);
          }
        })
        .catch((err) => {});
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
    debounce: _.debounce(function () {
      this.searchMessage();
    }, 500),
    returnInitial(lead){
      let initialsOrigin = lead.name.length > 2 ? lead.name : lead.email.length > 2 ? lead.email : null
      if (initialsOrigin) {
        var name = initialsOrigin.split(" ")[0];
        var sobrenome = initialsOrigin.split(" ")[1] ?? undefined;
        if (!sobrenome) {
          return name.substring(0, 2);
        }
        return name[0] + sobrenome[0];
      }
      return ''
    },
    getMetaInitial(user) {
      if (user) {
        var name = user?.first_name || "";
        var sobrenome = user?.last_name || "";
      }
      if (!name && !sobrenome) {
        return user?.email.substring(0, 2);
      }
      if (!name) {
        return sobrenome.substring(0, 2);
      }
      if (!sobrenome) {
        return name.substring(0, 2);
      }
      return name[0] + sobrenome[0];
    },
    newLeads(item){
      this.leadsPage = 1;
      this.leads = [];
      this.lead = {id: 0};
      this.sellerSelect = false
      this.selectedLead = {}
      this.vendedor = item
      this.leadSearch = ''
      this.stopLeads = false
      this.$refs.WhatsAppMessages.days = [];
      this.$refs.WhatsAppMessages.loading = true;
      this.getLeads(false)
    },
    debounceLeads: _.debounce(function () {
      this.getLeads(this.clean);
    }, 400),
    getLeads(clean){
      if (clean) {
        this.leadsPage = 1;
        this.stopLeads = false;
      }
      if (this.stopLeads) {
        return
      }
      this.stopLeads = true
      let data = {
        id: `/leads`,
        page: this.leadsPage,
        search: this.leadSearch,
        user_id: this.vendedor ? this.vendedor.id : '',
      };
      LogMessages.createId(data)
        .then((resp) => {
          if (resp.last_page > resp.current_page) {
            this.stopLeads = false;
          }
          this.leadsPage++
          if (clean) {
            this.leads = resp.data
          } else {
            this.leads = this.leads.concat(resp.data)
          }
        })
        .catch((err) => {
        });
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();

    // validação
    if (
      this.level != "owner" &&
      this.level != "admin" &&
      this.level != "commercial_leader"
    ) {
      this.$router.push("/dynamicRoute/AccessDenied");
    }
  },
  mounted() {
    this.getLeads()
    this.debounceVendedor()
    // this.getMessages();
  },
};
</script>

<style lang="scss" scoped>
@keyframes example {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.container{
  position: relative;
}
.AnimationRight-enter-active {
  animation: example 0.2s;
  animation-timing-function: ease-out;
}

.AnimationRight-leave-active {
  animation: example 0.2s reverse;
  animation-timing-function: ease-in;
}
.observer {
  height: 10px;
  margin: -10px;
  width: 100%;
}
.sub-card {
  cursor: pointer;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 12px;
  height: fit-content;
  transition: all 0.3s;
  position: relative;
  &:hover {
    background-color: #efefef4d;
  }
  &:last-child {
    margin-bottom: 0;
  }

  .sellers-name {
    max-width: 100%;
    overflow: hidden;
    color: var(--gray03);
    font-size: 12px;
    line-height: 1.2;
  }
}

.log-header {
  align-items: center;
  img {
    width: 35px;
    height: 35px;
    object-fit: cover;
    border-radius: 100%;
  }
  padding: 10px 20px;
  background-color: var(--greenn);
  color: var(--white-light);
  .user-info {
    display: block;
    width: 100%;
    .name {
      font-size: 15px;
      font-weight: 500;
      display: block;
      color: white;
    }
    .email {
      line-height: 1.2;
      font-size: 12px;
      color: white;
      display: block;
      font-weight: 300;
    }
  }
}

.lead-search2 {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  input {
    width: 100%;
    height: 30px !important;
    margin: 0 !important;
    padding-left: 30px !important;
  }
  img {
    width: 17px;
    height: 17px;
    position: absolute;
    top: 8px;
    left: 8px;
  }
}
.user-header {
  position: relative;
  display: grid;
  grid-template-columns: 35px calc(100% - 65px) 10px;
  width: 100%;
  gap: 10px;
  border-right: 1px solid #dbdbdb;
  cursor: pointer;
  .select {
    background-color: white;
    width: 10px;
    height: 6px;
    transition: all 0.3s;
    clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
  }

  .seller-select {
    position: absolute;
    width: 90%;
    margin-left: 5%;
    top: 90%;
    left: 0;
    background-color: #FFF;
    z-index: 10;
    border-radius: 10px;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 15px;
  }

  span {
    max-width: calc(100%);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: white;
    display: block;
    margin-bottom: 0;
  }
}

.lead-header{
  .lead-exist {
    height: 100%;
    display: grid;
    grid-template-columns: 35px 1fr;
    align-items: center;
    gap: 10px;
    width: fit-content;
    .user-info {
      width: fit-content;
      max-width: 100%;
    }
    *{
      cursor: pointer;
    }
  }
}
.box-zap {
  display: grid;
  grid-template-columns: 30% 70%;
  grid-template-rows: 63px calc(100% - 63px);
  border: 1px solid #dbdbdb;
  border-radius: 10px;
  overflow: hidden;
  height: calc(100vh - 240px);
}

.body-left {
  background-color: #f5f5f5;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-right: none;
  width: 100%;
  height: 100% !important;
  > div:last-child {
    overflow-y: auto;
    height: calc(100% - 52px);
  }
}

.list-left {
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-bottom: 1px solid #dbdbdb;
  width: 100%;
  padding: 10px 22px;
  cursor: pointer;
  transition: all 0.1s;

  &:active {
    background-color: #e2e2e2;
  }

  .userId {
    width: 100%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-size: 11px;
      margin-top: 3px;
    }

    p {
      font-weight: 600;
      margin-bottom: 0px !important;
      width: 5px;
      height: 5px;
      margin-top: -5px;
      margin-left: -2px;
    }
  }
}
.lead-search {
  width: 100%;
  position: relative;
  img {
    position: absolute;
    top: 21px;
    left: 22px;
  }
  input {
    padding: 8px 8px 8px 35px !important;
    height: auto !important;
    width: calc(100% - 20px);
    border-radius: 10px;
    border: 1px solid #dbdbdb;
    margin: 10px 10px 4px !important;
  }
}
.selected-contact {
  background-color: var(--greenn2);
  span {
    color: var(--greenn) !important;
  }
}

.initial {
  height: 50px;
  width: 50px;
  border-radius: 100%;
  background-color: var(--greenn2);
  border: 2px solid var(--greenn);
  text-align: center;
  font-size: 23px;
  color: var(--greenn);
  text-transform: uppercase;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.small {
  height: 35px !important;
  width: 35px !important;
  font-size: 16px !important;
}
.all-white {
  color: white !important;
  background-color: #FFF4 !important;
  border-color: white;
}
.contact {
  display: grid;
  padding: 15px;
  grid-template-columns: 50px calc(100% - 55px);
  gap: 10px;
  align-items: center;
  border-bottom: 1px solid #dbdbdb;
  cursor: pointer;
  transition: background-color 0.3s;
  img {
    height: 45px;
    width: 45px;
    object-fit: cover;
    border-radius: 100%;
  }
  span {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--gray03);
    transition: color 0.3s;
  }
  .lead-name {
    font-weight: 600;
    font-size: 16px;
    display: block;
  }
  .lead-phone {
    display: block;
    font-weight: 400;
    font-size: 13px;
  }
}
.nao-encontrado {
  font-size: 14px;
  color: #81858e;
  font-weight: normal;
  border-radius: 10px;
  padding: 20px !important;
  background: #f7f7f7;
  margin: 20px auto;
}
</style>
