<template>
    <div>
        <LogMessages></LogMessages>
    </div>

</template>

<script>
import BaseView from '@/template/BaseView.vue';
import LogMessages from '@/components/Apps/LogMessages/LogMessages.vue';


export default {
  components: {
    BaseView,
    LogMessages
  },
  // created() {
  //   if (this.$store.getters.user.user.level == 'seller') {this.$router.push("/dynamicRoute/AccessDenied")};
  // },
}
</script>